'use strict';

/* Init Angular App */

var netStatsApp = angular.module('netStatsApp', ['netStatsApp.filters', 'netStatsApp.directives', 'ngStorage']);


/* Services */

netStatsApp.factory('socket', function ($rootScope) {
	var socket = new Primus();
	return socket;
});

netStatsApp.factory('toastr', function ($rootScope) {
	toastr = window.toastr;
	toastr.options = {
		"closeButton": false,
		"debug": false,
		"progressBar": false,
		"newestOnTop": true,
		"positionClass": "toast-top-right",
		"preventDuplicates": false,
		"onclick": null,
		"showDuration": "300",
		"hideDuration": "1000",
		"timeOut": "5000",
		"extendedTimeOut": "1000",
		"showEasing": "swing",
		"hideEasing": "linear",
		"showMethod": "fadeIn",
		"hideMethod": "fadeOut"
	};
	return toastr;
});

netStatsApp.factory('_', function ($rootScope) {
	var lodash = window._;
	return lodash;
});
;

/* Controllers */

netStatsApp.controller('StatsCtrl', function($scope, $timeout, $filter, $localStorage, socket, _, toastr) {

	var MAX_BINS = 40;

    //$timeout(reloadScreen, 60000);

	// Main Stats init
	// ---------------

	$scope.frontierHash = '0x11bbe8db4e347b4e8c937c1c8370e4b5ed33adb3db69cbdb7a38e1e50b1b82fa';
	$scope.nodesTotal = 0;
	$scope.nodesActive = 0;
	$scope.bestBlock = 0;
	$scope.lastBlock = 0;
	$scope.lastDifficulty = 0;
	$scope.upTimeTotal = 0;
	$scope.avgBlockTime = 0;
	$scope.blockPropagationAvg = 0;
	$scope.avgHashrate = 0;
	$scope.uncleCount = 0;
	$scope.bestStats = {};

	$scope.lastGasLimit = _.fill(Array(MAX_BINS), 2);
	$scope.lastBlocksTime = _.fill(Array(MAX_BINS), 2);
	$scope.difficultyChart = _.fill(Array(MAX_BINS), 2);
	$scope.transactionDensity = _.fill(Array(MAX_BINS), 2);
	$scope.gasSpending = _.fill(Array(MAX_BINS), 2);
	$scope.miners = [];


	$scope.nodes = [];
	$scope.map = [];
	$scope.blockPropagationChart = [];
	$scope.uncleCountChart = _.fill(Array(MAX_BINS), 2);
	$scope.coinbases = [];

	$scope.latency = 0;

	$scope.currentApiVersion = "0.1.1";

	$scope.predicate = $localStorage.predicate || ['-pinned', '-stats.active', '-stats.block.number', 'stats.block.propagation'];
	$scope.reverse = $localStorage.reverse || false;
	$scope.pinned = $localStorage.pinned || [];

	$scope.prefixPredicate = ['-pinned', '-stats.active'];
	$scope.originalPredicate = ['-stats.block.number', 'stats.block.propagation'];

	$scope.orderTable = function(predicate, reverse)
	{
		if(!_.isEqual(predicate, $scope.originalPredicate))
		{
			$scope.reverse = reverse;
			$scope.originalPredicate = predicate;
			$scope.predicate = _.union($scope.prefixPredicate, predicate);
		}
		else
		{
			$scope.reverse = !$scope.reverse;

			if($scope.reverse === true){
				_.forEach(predicate, function (value, key) {
					predicate[key] = (value[0] === '-' ? value.replace('-', '') : '-' + value);
				});
			}

			$scope.predicate = _.union($scope.prefixPredicate, predicate);
		}

		$localStorage.predicate = $scope.predicate;
		$localStorage.reverse = $scope.reverse;
	}

	$scope.pinNode = function(id)
	{
		index = findIndex({id: id});

		if( !_.isUndefined($scope.nodes[index]) )
		{
			$scope.nodes[index].pinned = !$scope.nodes[index].pinned;

			if($scope.nodes[index].pinned)
			{
				$scope.pinned.push(id);
			}
			else
			{
				$scope.pinned.splice($scope.pinned.indexOf(id), 1);
			}
		}

		$localStorage.pinned = $scope.pinned;
	}

	var timeout = setInterval(function ()
	{
		$scope.$apply();
	}, 300);

	$scope.getNumber = function (num) {
		return new Array(num);
	}

	// Socket listeners
	// ----------------

	socket.on('open', function open() {
		socket.emit('ready');
		console.log('The connection has been opened.');
	})
	.on('end', function end() {
		console.log('Socket connection ended.')
	})
	.on('error', function error(err) {
		console.log(err);
	})
	.on('reconnecting', function reconnecting(opts) {
		console.log('We are scheduling a reconnect operation', opts);
	})
	.on('data', function incoming(data) {
		$scope.$apply(socketAction(data.action, data.data));
	});

	socket.on('init', function(data)
	{		
		$scope.$apply(socketAction("init", data.nodes));
	});

	socket.on('client-latency', function(data)
	{
		$scope.latency = data.latency;
	})

	function socketAction(action, data)
	{
		// filter data
		data = xssFilter(data);

		// console.log('Action: ', action);
		// console.log('Data: ', data);

		switch(action)
		{
			case "init":
				$scope.nodes = data;

				_.forEach($scope.nodes, function (node, index) {

					// Init hashrate
					if( _.isUndefined(node.stats.hashrate) )
						node.stats.hashrate = 0;

					// Init latency
					latencyFilter(node);

					// Init history
					if( _.isUndefined(data.history) )
					{
						data.history = new Array(40);
						_.fill(data.history, -1);
					}

					// Init or recover pin
					node.pinned = ($scope.pinned.indexOf(node.id) >= 0 ? true : false);
				});

				if( $scope.nodes.length > 0 )
				{
					toastr['success']("Got nodes list", "Got nodes!");

					updateActiveNodes();
				}

				break;

			case "add":
				var index = findIndex({id: data.id});

				// if( addNewNode(data) )
				// 	toastr['success']("New node "+ $scope.nodes[findIndex({id: data.id})].info.name +" connected!", "New node!");
				// else
				// 	toastr['info']("Node "+ $scope.nodes[index].info.name +" reconnected!", "Node is back!");

				break;

			// TODO: Remove when everybody updates api client to 0.0.12
			case "update":
				var index = findIndex({id: data.id});

				if( index >= 0 && !_.isUndefined($scope.nodes[index]) && !_.isUndefined($scope.nodes[index].stats) )
				{
					if( !_.isUndefined($scope.nodes[index].stats.latency) )
						data.stats.latency = $scope.nodes[index].stats.latency;

					if( _.isUndefined(data.stats.hashrate) )
						data.stats.hashrate = 0;

					if( $scope.nodes[index].stats.block.number < data.stats.block.number )
					{
						var best = _.max($scope.nodes, function (node) {
							return parseInt(node.stats.block.number);
						}).stats.block;

						if (data.stats.block.number > best.number) {
							data.stats.block.arrived = _.now();
						} else {
							data.stats.block.arrived = best.arrived;
						}

						$scope.nodes[index].history = data.history;
					}

					$scope.nodes[index].stats = data.stats;

					if( !_.isUndefined(data.stats.latency) && _.get($scope.nodes[index], 'stats.latency', 0) !== data.stats.latency )
					{
						$scope.nodes[index].stats.latency = data.stats.latency;

						latencyFilter($scope.nodes[index]);
					}

					updateBestBlock();
				}

				break;

			case "block":
				var index = findIndex({id: data.id});

				if( index >= 0 && !_.isUndefined($scope.nodes[index]) && !_.isUndefined($scope.nodes[index].stats) )
				{
					if( $scope.nodes[index].stats.block.number < data.block.number )
					{
						var best = _.max($scope.nodes, function (node) {
							return parseInt(node.stats.block.number);
						}).stats.block;

						if (data.block.number > best.number) {
							data.block.arrived = _.now();
						} else {
							data.block.arrived = best.arrived;
						}

						$scope.nodes[index].history = data.history;
					}

					$scope.nodes[index].stats.block = data.block;
					$scope.nodes[index].stats.propagationAvg = data.propagationAvg;

					updateBestBlock();
				}

				break;

			case "pending":
				var index = findIndex({id: data.id});

				if( !_.isUndefined(data.id) && index >= 0 )
				{
					var node = $scope.nodes[index];

					if( !_.isUndefined(node) && !_.isUndefined(node.stats.pending) && !_.isUndefined(data.pending) )
						$scope.nodes[index].stats.pending = data.pending;
				}

				break;

			case "stats":
				var index = findIndex({id: data.id});

				if( !_.isUndefined(data.id) && index >= 0 )
				{
					var node = $scope.nodes[index];

					if( !_.isUndefined(node) && !_.isUndefined(node.stats) )
					{
						$scope.nodes[index].stats.active = data.stats.active;
						$scope.nodes[index].stats.mining = data.stats.mining;
						$scope.nodes[index].stats.hashrate = data.stats.hashrate;
						$scope.nodes[index].stats.peers = data.stats.peers;
						$scope.nodes[index].stats.gasPrice = data.stats.gasPrice;
						$scope.nodes[index].stats.uptime = data.stats.uptime;

						if( !_.isUndefined(data.stats.latency) && _.get($scope.nodes[index], 'stats.latency', 0) !== data.stats.latency )
						{
							$scope.nodes[index].stats.latency = data.stats.latency;

							latencyFilter($scope.nodes[index]);
						}

						updateActiveNodes();
					}
				}

				break;

			case "info":
				var index = findIndex({id: data.id});

				if( index >= 0 )
				{
					$scope.nodes[index].info = data.info;

					if( _.isUndefined($scope.nodes[index].pinned) )
						$scope.nodes[index].pinned = false;

					// Init latency
					latencyFilter($scope.nodes[index]);

					updateActiveNodes();
				}

				break;

			case "blockPropagationChart":
				$scope.blockPropagationChart = data.histogram;
				$scope.blockPropagationAvg = data.avg;

				break;

			case "uncleCount":
				$scope.uncleCount = data[0] + data[1];
				data.reverse();
				$scope.uncleCountChart = data;

				break;

			case "charts":
				if( !_.isEqual($scope.avgBlockTime, data.avgBlocktime) )
					$scope.avgBlockTime = data.avgBlocktime;

				if( !_.isEqual($scope.avgHashrate, data.avgHashrate) )
					$scope.avgHashrate = data.avgHashrate;

				if( !_.isEqual($scope.lastGasLimit, data.gasLimit) && data.gasLimit.length >= MAX_BINS )
					$scope.lastGasLimit = data.gasLimit;

				if( !_.isEqual($scope.lastBlocksTime, data.blocktime) && data.blocktime.length >= MAX_BINS )
					$scope.lastBlocksTime = data.blocktime;

				if( !_.isEqual($scope.difficultyChart, data.difficulty) && data.difficulty.length >= MAX_BINS )
					$scope.difficultyChart = data.difficulty;

				if( !_.isEqual($scope.blockPropagationChart, data.propagation.histogram) ) {
					$scope.blockPropagationChart = data.propagation.histogram;
					$scope.blockPropagationAvg = data.propagation.avg;
				}

				data.uncleCount.reverse();

				if( !_.isEqual($scope.uncleCountChart, data.uncleCount) && data.uncleCount.length >= MAX_BINS ) {
					$scope.uncleCount = data.uncleCount[data.uncleCount.length-2] + data.uncleCount[data.uncleCount.length-1];
					$scope.uncleCountChart = data.uncleCount;
				}

				if( !_.isEqual($scope.transactionDensity, data.transactions) && data.transactions.length >= MAX_BINS )
					$scope.transactionDensity = data.transactions;

				if( !_.isEqual($scope.gasSpending, data.gasSpending) && data.gasSpending.length >= MAX_BINS )
					$scope.gasSpending = data.gasSpending;

				if( !_.isEqual($scope.miners, data.miners) ) {
					$scope.miners = data.miners;
					getMinersNames();
				}

				break;

			case "inactive":
				var index = findIndex({id: data.id});

				if( index >= 0 )
				{
					if( !_.isUndefined(data.stats) )
						$scope.nodes[index].stats = data.stats;

					// toastr['error']("Node "+ $scope.nodes[index].info.name +" went away!", "Node connection was lost!");

					updateActiveNodes();
				}

				break;

			case "latency":
				if( !_.isUndefined(data.id) && !_.isUndefined(data.latency) )
				{
					var index = findIndex({id: data.id});

					if( index >= 0 )
					{
						var node = $scope.nodes[index];

						if( !_.isUndefined(node) && !_.isUndefined(node.stats) && !_.isUndefined(node.stats.latency) && node.stats.latency !== data.latency )
						{
							node.stats.latency = data.latency;
							latencyFilter(node);
						}
					}
				}

				break;

			case "client-ping":
			
				var serverTime = data.serverTime;
				/*
				console.log("===========================================");
				console.log("client time: " + new Date());
				console.log("server time: " + new Date(serverTime));
				console.log("===========================================");
				*/
				
				$scope.serverTime = serverTime;
				$scope.timeDifference = serverTime - _.now();
				if ($scope.timeDifference < 3000) 
					$scope.timeDifference = 0;
				
				
				socket.emit('client-pong', {
					serverTime: serverTime,
					clientTime: _.now()
				});

				break;
		}

		// $scope.$apply();
	}

	function findIndex(search)
	{
		return _.findIndex($scope.nodes, search);
	}

	function getMinersNames()
	{
		if( $scope.miners.length > 0 )
		{
			_.forIn($scope.miners, function (value, key)
			{
				if(value.name !== false)
					return;

				if(value.miner === "0x0000000000000000000000000000000000000000")
					return;

				var name = _.result(_.find(_.pluck($scope.nodes, 'info'), 'coinbase', value.miner), 'name');

				if( !_.isUndefined(name) )
					$scope.miners[key].name = name;
			});
		}
	}

	function addNewNode(data)
	{
		var index = findIndex({id: data.id});

		if( _.isUndefined(data.history) )
		{
			data.history = new Array(40);
			_.fill(data.history, -1);
		}

		if( index < 0 )
		{
			if( !_.isUndefined(data.stats) && _.isUndefined(data.stats.hashrate) )
			{
				data.stats.hashrate = 0;
			}

			data.pinned = false;

			$scope.nodes.push(data);

			return true;
		}

		data.pinned = ( !_.isUndefined($scope.nodes[index].pinned) ? $scope.nodes[index].pinned : false);

		if( !_.isUndefined($scope.nodes[index].history) )
		{
			data.history = $scope.nodes[index].history;
		}

		$scope.nodes[index] = data;

		updateActiveNodes();

		return false;
	}

	function updateActiveNodes()
	{
		updateBestBlock();

		$scope.nodesTotal = $scope.nodes.length;

		$scope.nodesActive = _.filter($scope.nodes, function (node) {
			// forkFilter(node);
			return node.stats.active == true;
		}).length;

		$scope.upTimeTotal = _.reduce($scope.nodes, function (total, node) {
			return total + node.stats.uptime;
		}, 0) / $scope.nodes.length;

		$scope.map = _.map($scope.nodes, function (node) {
			var fill = $filter('bubbleClass')(node.stats, $scope.bestBlock);

			if(node.geo != null)
				return {
					radius: 3,
					latitude: node.geo.ll[0],
					longitude: node.geo.ll[1],
					nodeName: node.info.name,
					fillClass: "text-" + fill,
					fillKey: fill,
				};
			else
				return {
					radius: 0,
					latitude: 0,
					longitude: 0
				};
		});
	}

	function updateBestBlock()
	{
		if( $scope.nodes.length )
		{
			var chains = {};
			var maxScore = 0;

			// _($scope.nodes)
			// 	.map(function (item)
			// 	{
			// 		maxScore += (item.trusted ? 50 : 1);

			// 		if( _.isUndefined(chains[item.stats.block.number]) )
			// 			chains[item.stats.block.number] = [];

			// 		if( _.isUndefined(chains[item.stats.block.number][item.stats.block.fork]) )
			// 			chains[item.stats.block.number][item.stats.block.fork] = {
			// 				fork: item.stats.block.fork,
			// 				count: 0,
			// 				trusted: 0,
			// 				score: 0
			// 			};

			// 		if(item.stats.block.trusted)
			// 			chains[item.stats.block.number][item.stats.block.fork].trusted++;
			// 		else
			// 			chains[item.stats.block.number][item.stats.block.fork].count++;

			// 		chains[item.stats.block.number][item.stats.block.fork].score = chains[item.stats.block.number][item.stats.block.fork].trusted * 50 + chains[item.stats.block.number][item.stats.block.fork].count;
			// 	})
			// 	.value();

			// $scope.maxScore = maxScore;
			// $scope.chains = _.reduce(chains, function (result, item, key)
			// {
			// 	result[key] = _.max(item, 'score');
			// 	return result;
			// }, {});

			var bestBlock = _.max($scope.nodes, function (node)
			{
				// if( $scope.chains[node.stats.block.number].fork === node.stats.block.fork && $scope.chains[node.stats.block.number].score / $scope.maxScore >= 0.5 )
				// {
					return parseInt(node.stats.block.number);
				// }

				// return 0;
			}).stats.block.number;

			if( bestBlock !== $scope.bestBlock )
			{
				$scope.bestBlock = bestBlock;
				$scope.bestStats = _.max($scope.nodes, function (node) {
					return parseInt(node.stats.block.number);
				}).stats;

				$scope.lastBlock = $scope.bestStats.block.arrived;
				$scope.lastDifficulty = $scope.bestStats.block.difficulty;
			}
		}
	}

	// function forkFilter(node)
	// {
	// 	if( _.isUndefined(node.readable) )
	// 		node.readable = {};

	// 	node.readable.forkClass = 'hidden';
	// 	node.readable.forkMessage = '';

	// 	return true;

	// 	if( $scope.chains[node.stats.block.number].fork === node.stats.block.fork && $scope.chains[node.stats.block.number].score / $scope.maxScore >= 0.5 )
	// 	{
	// 		node.readable.forkClass = 'hidden';
	// 		node.readable.forkMessage = '';

	// 		return true;
	// 	}

	// 	if( $scope.chains[node.stats.block.number].fork !== node.stats.block.fork )
	// 	{
	// 		node.readable.forkClass = 'text-danger';
	// 		node.readable.forkMessage = 'Wrong chain.<br/>This chain is a fork.';

	// 		return false;
	// 	}

	// 	if( $scope.chains[node.stats.block.number].score / $scope.maxScore < 0.5)
	// 	{
	// 		node.readable.forkClass = 'text-warning';
	// 		node.readable.forkMessage = 'May not be main chain.<br/>Waiting for more confirmations.';

	// 		return false;
	// 	}
	// }

	function latencyFilter(node)
	{
		if( _.isUndefined(node.readable) )
			node.readable = {};

		if( _.isUndefined(node.stats) ) {
			node.readable.latencyClass = 'text-danger';
			node.readable.latency = 'offline';
		}

		if (node.stats.active === false)
		{
			node.readable.latencyClass = 'text-danger';
			node.readable.latency = 'offline';
		}
		else
		{
			if (node.stats.latency <= 100)
				node.readable.latencyClass = 'text-success';

			if (node.stats.latency > 100 && node.stats.latency <= 1000)
				node.readable.latencyClass = 'text-warning';

			if (node.stats.latency > 1000)
				node.readable.latencyClass = 'text-danger';

			node.readable.latency = node.stats.latency + ' ms';
		}
	}

	// very simple xss filter
	function xssFilter(obj){
		if(_.isArray(obj)) {
			return _.map(obj, xssFilter);

		} else if(_.isObject(obj)) {
			return _.mapValues(obj, xssFilter);

		} else if(_.isString(obj)) {
			return obj.replace(/\< *\/* *script *>*/gi,'').replace(/javascript/gi,'');
		} else
			return obj;
	}

	function reloadScreen() {
	    location.reload();
	}
});;

/* Filters */

angular.module('netStatsApp.filters', [])
.filter('nodesActiveClass', function() {
	return function(active, total) {
		var ratio = active/total;

		if(ratio >= 0.9)
			return 'text-success';

		if(ratio >= 0.75)
			return 'text-info';

		if(ratio >= 0.5)
			return 'text-warning';

		return 'text-danger';
	};
})
.filter('nodePinClass', function() {
	return function(pinned) {
	  	if(pinned)
	  		return 'icon-check-o';

	  	return 'icon-loader';
	};
})
.filter('mainClass', function() {
	return function(node, bestBlock) {
	  	return mainClass(node, bestBlock);
	};
})
.filter('peerClass', function() {
	return function(peers, active) {
		return peerClass(peers, active);
	};
})
.filter('miningClass', function() {
	return function(mining, active) {
		if(! active)
			return 'text-gray';

		return (! mining ? 'text-danger' : 'text-success');
	};
})
.filter('miningIconClass', function() {
	return function(mining) {
		return (! mining ? 'icon-cancel' : 'icon-check');
	};
})
.filter('hashrateClass', function() {
	return function(mining, active) {
		if(! mining || ! active)
			return 'text-gray';

		return 'text-success';
	};
})
.filter('hashrateFilter', ['$sce', '$filter', function($sce, filter) {
	return function(hashes, isMining) {
		var result = 0;
		var unit = 'K';

		if( !isMining )
			return $sce.trustAsHtml('<i class="icon-cancel"></i>');

		if(hashes !== 0 && hashes < 1000) {
			result = hashes;
			unit = '';
		}

		if(hashes >= 1000 && hashes < Math.pow(1000, 2)) {
			result = hashes / 1000;
			unit = 'K';
		}

		if(hashes >= Math.pow(1000, 2) && hashes < Math.pow(1000, 3)) {
			result = hashes / Math.pow(1000, 2);
			unit = 'M';
		}

		if(hashes >= Math.pow(1000, 3) && hashes < Math.pow(1000, 4)) {
			result = hashes / Math.pow(1000, 3);
			unit = 'G';
		}

		if(hashes >= Math.pow(1000, 4) && hashes < Math.pow(1000, 5)) {
			result = hashes / Math.pow(1000, 4);
			unit = 'T';
		}

		if(hashes >= Math.pow(1000, 5) && hashes < Math.pow(1000, 6)) {
			result = hashes / Math.pow(1000, 5);
			unit = 'P';
		}

		if(hashes >= Math.pow(1000, 6) && hashes < Math.pow(1000, 7)) {
			result = hashes / Math.pow(1000, 6);
			unit = 'E';
		}

		if(hashes >= Math.pow(1000, 7) && hashes < Math.pow(1000, 8)) {
			result = hashes / Math.pow(1000, 7);
			unit = 'Z';
		}

		if(hashes >= Math.pow(1000, 8) && hashes < Math.pow(1000, 9)) {
			result = hashes / Math.pow(1000, 8);
			unit = 'Y';
		}

		return $sce.trustAsHtml('<span class="small">' + filter('number')(result.toFixed(2)) + ' <span class="small-hash">' + unit + 'H/s</span></span>');
	};
}])
.filter('totalDifficultyFilter', function() {
	return function(hashes) {
		var result = 0;
		var unit = '';

		if(hashes !== 0 && hashes < 1000) {
			result = hashes;
			unit = '';
		}

		if(hashes >= 1000 && hashes < Math.pow(1000, 2)) {
			result = hashes / 1000;
			unit = 'K';
		}

		if(hashes >= Math.pow(1000, 2) && hashes < Math.pow(1000, 3)) {
			result = hashes / Math.pow(1000, 2);
			unit = 'M';
		}

		if(hashes >= Math.pow(1000, 3) && hashes < Math.pow(1000, 4)) {
			result = hashes / Math.pow(1000, 3);
			unit = 'G';
		}

		if(hashes >= Math.pow(1000, 4) && hashes < Math.pow(1000, 5)) {
			result = hashes / Math.pow(1000, 4);
			unit = 'T';
		}

		if(hashes >= Math.pow(1000, 5) && hashes < Math.pow(1000, 6)) {
			result = hashes / Math.pow(1000, 5);
			unit = 'P';
		}

		if(hashes >= Math.pow(1000, 6) && hashes < Math.pow(1000, 7)) {
			result = hashes / Math.pow(1000, 6);
			unit = 'E';
		}

		if(hashes >= Math.pow(1000, 7) && hashes < Math.pow(1000, 8)) {
			result = hashes / Math.pow(1000, 7);
			unit = 'Z';
		}

		if(hashes >= Math.pow(1000, 8) && hashes < Math.pow(1000, 9)) {
			result = hashes / Math.pow(1000, 8);
			unit = 'Y';
		}

		return result.toFixed(2) + ' ' + unit + 'H';
	};
})
.filter('nodeVersion', function($sce) {
	return function(version) {
		if(typeof version !== 'undefined')
		{
			var tmp = version.split('/');

			tmp[0] = tmp[0].replace('Ethereum(++)', 'Eth');

			if(tmp[0].indexOf('pyethapp') === 0)
			{
				tmp[0] = 'pyeth';
			}

			if(tmp[1][0] !== 'v' && tmp[1][2] !== '.')
			{
				tmp.splice(1,1);
			}

			if(tmp[2] === 'Release'){
				tmp.splice(2,1);
			}

			if(tmp[2].indexOf('Linux') === 0)
				tmp[2] = 'linux';

			if(tmp[2].indexOf('Darwin') === 0)
				tmp[2] = 'darwin';

			return $sce.trustAsHtml(tmp.join('/'));
		}

		return '';
	};
})
.filter('blockClass', function() {
	return function(current, best) {
		if( ! current.active)
			return 'text-gray';

		return (best - current.block.number < 1 ? 'text-success' : (best - current.block.number === 1 ? 'text-warning' : (best - current.block.number > 1 && best - current.block.number < 4 ? 'text-orange' : 'text-danger')));
	};
})
.filter('gasPriceFilter', ['$filter', function(filter) {
	var numberFilter = filter('number');
	return function(price) {
		if(typeof price === 'undefined')
			return "0 wei";

		if(price.length < 4)
			return numberFilter(price) + " wei";

		if(price.length < 7)
			return numberFilter(price/1000) + " kwei";

		if(price.length < 10)
			return numberFilter(price/1000000) + " mwei";

		if(price.length < 13)
			return numberFilter(price/1000000000) + " gwei";

		if(price.length < 16)
			return numberFilter(price/1000000000000) + " szabo";

		if(price.length < 19)
			return numberFilter(price.substr(0, price.length - 15)) + " finney";

		return numberFilter(price.substr(0, price.length - 18)) + " ether";
	}
}])
.filter('gasFilter', function() {
	return function(gas) {
		return (typeof gas !== 'undefined' ? parseInt(gas) : '?');
	}
})
.filter('hashFilter', function() {
	return function(hash) {
		if(typeof hash === 'undefined')
			return "?";

		if(hash.substr(0,2) === '0x')
			hash = hash.substr(2,64);

		return hash.substr(0, 8) + '...' + hash.substr(56, 8);
	}
})
.filter('timeClass', function() {
	return function(timestamp, active) {
		if( ! active)
			return 'text-gray';

		return timeClass(timestamp);
	};
})
.filter('propagationTimeClass', function() {
	return function(stats, bestBlock) {
		if( ! stats.active)
			return 'text-gray';

		if(stats.block.number < bestBlock)
			return 'text-gray';

		if(stats.block.propagation == 0)
			return 'text-info';

		if(stats.block.propagation < 1000)
			return 'text-success';

		if(stats.block.propagation < 3000)
			return 'text-warning';

		if(stats.block.propagation < 7000)
			return 'text-orange';

		return 'text-danger'
	};
})
.filter('propagationNodeAvgTimeClass', function() {
	return function(stats, bestBlock) {
		if( ! stats.active)
			return 'text-gray';

		if(stats.block.number < bestBlock)
			return 'text-gray';

		if(stats.propagationAvg == 0)
			return 'text-info';

		if(stats.propagationAvg < 1000)
			return 'text-success';

		if(stats.propagationAvg < 3000)
			return 'text-warning';

		if(stats.propagationAvg < 7000)
			return 'text-orange';

		return 'text-danger'
	};
})
.filter('propagationAvgTimeClass', function() {
	return function(propagationAvg, active) {
		if( ! active)
			return 'text-gray';

		if(propagationAvg == 0)
			return 'text-info';

		if(propagationAvg < 1000)
			return 'text-success';

		if(propagationAvg < 3000)
			return 'text-warning';

		if(propagationAvg < 7000)
			return 'text-orange';

		return 'text-danger'
	};
})
.filter('latencyFilter', function() {
	return function(stats) {
		if(stats.active === false)
			return 'offline';
		else
			return stats.latency + ' ms';
	}
})
.filter('latencyClass', function() {
	return function(stats) {
		if(stats.active === false)
			return 'text-danger';

		if(stats.latency <= 100)
			return 'text-success';

		if(stats.latency <= 1000)
			return 'text-warning';

		return 'text-danger'
	};
})
.filter('blockTimeFilter', function() {
	return function(timestamp) {
		
		if(timestamp === 0)
			return '∞';

		// var time = Math.floor((new Date()).getTime() / 1000);
		
		// Ruben - testing new time difference calcuation not based on client time
		var time = (new Date()).getTime();
		//var diff = Math.floor((time - timestamp)/1000);
		
		var diff = Math.floor((time + this.timeDifference - timestamp )/1000);
		
		
		console.log("===========================================");
		console.log("client time: " + new Date(time));
		console.log("adjusted time time: " + new Date(time + this.timeDifference));
		console.log("last block: " + new Date(timestamp));
		console.log("===========================================");
				
		
		var absVal = Math.abs(diff); 

		var result = '';
		if(absVal < 60) {
			result += Math.round(absVal) + ' s ';
		} else {
			result += moment.duration(Math.round(absVal), 's').humanize() + ' ';
		}

		if(diff >= 0) {
			result += ' ago';
		} else {
			result = '0 s';
			// result += ' ITF';
		}
		
		return result; 
	};
})
.filter('networkHashrateFilter', ['$sce', '$filter', function($sce, filter) {
	return function(hashes, isMining) {
		if(hashes === null)
			hashes = 0;

		var result = 0;
		var unit = 'K';

		if(hashes !== 0 && hashes < 1000) {
			result = hashes;
			unit = '';
		}

		if(hashes >= 1000 && hashes < Math.pow(1000, 2)) {
			result = hashes / 1000;
			unit = 'K';
		}

		if(hashes >= Math.pow(1000, 2) && hashes < Math.pow(1000, 3)) {
			result = hashes / Math.pow(1000, 2);
			unit = 'M';
		}

		if(hashes >= Math.pow(1000, 3) && hashes < Math.pow(1000, 4)) {
			result = hashes / Math.pow(1000, 3);
			unit = 'G';
		}

		if(hashes >= Math.pow(1000, 4) && hashes < Math.pow(1000, 5)) {
			result = hashes / Math.pow(1000, 4);
			unit = 'T';
		}

		if(hashes >= Math.pow(1000, 5) && hashes < Math.pow(1000, 6)) {
			result = hashes / Math.pow(1000, 5);
			unit = 'P';
		}

		if(hashes >= Math.pow(1000, 6) && hashes < Math.pow(1000, 7)) {
			result = hashes / Math.pow(1000, 6);
			unit = 'E';
		}

		if(hashes >= Math.pow(1000, 7) && hashes < Math.pow(1000, 8)) {
			result = hashes / Math.pow(1000, 7);
			unit = 'Z';
		}

		if(hashes >= Math.pow(1000, 8) && hashes < Math.pow(1000, 9)) {
			result = hashes / Math.pow(1000, 8);
			unit = 'Y';
		}


		if( !isMining )
			return $sce.trustAsHtml(filter('number')(result.toFixed(1)) + ' <span class="small-hash">' + unit + 'H/s</span>');

		return $sce.trustAsHtml('? <span class="small-hash">' + unit + 'KH/s</span>');
	};
}])
.filter('blockPropagationFilter', function() {
	return function(ms, prefix) {
		if(typeof prefix === 'undefined')
			prefix = '+';

		var result = 0;

		if(ms < 1000) {
			return (ms === 0 ? "" : prefix) + ms + " ms";
		}

		if(ms < 1000*60) {
			result = ms/1000;
			return prefix + result.toFixed(1) + " s";
		}

		if(ms < 1000*60*60) {
			result = ms/1000/60;
			return prefix + Math.round(result) + " min";
		}

		if(ms < 1000*60*60*24) {
			result = ms/1000/60/60;
			return prefix + Math.round(result) + " h";
		}

		result = ms/1000/60/60/24;
		return prefix + Math.round(result) + " days";
	};
})
.filter('blockPropagationAvgFilter', function() {
	return function(stats, bestBlock) {
		var ms = stats.propagationAvg;

		if(bestBlock - stats.block.number > 40)
			return "∞";
		//ms = _.now() - stats.block.received;

		prefix = '';

		var result = 0;

		if(ms < 1000) {
			return (ms === 0 ? "" : prefix) + ms + " ms";
		}

		if(ms < 1000*60) {
			result = ms/1000;
			return prefix + result.toFixed(1) + " s";
		}

		if(ms < 1000*60*60) {
			result = ms/1000/60;
			return prefix + Math.round(result) + " min";
		}

		if(ms < 1000*60*60*24) {
			result = ms/1000/60/60;
			return prefix + Math.round(result) + " h";
		}

		result = ms/1000/60/60/24;
		return prefix + Math.round(result) + " days";
	};
})
.filter('avgTimeFilter', function() {
	return function(time) {
		if(time < 60)
			return parseFloat(time).toFixed(2) + ' s';

		return moment.duration(Math.round(time), 's').humanize();
	};
})
.filter('avgTimeClass', function() {
	return function(time) {
		return blockTimeClass(time);
	}
})
.filter('upTimeFilter', function() {
	return function(uptime) {
		return Math.round(uptime) + '%';
	};
})
.filter('upTimeClass', function() {
	return function(uptime, active) {
		if( ! active )
			return 'text-gray';

		if(uptime >= 90)
			return 'text-success';

		if(uptime >= 75)
			return 'text-warning';

		return 'text-danger';
	};
})
.filter('geoTooltip', function() {
	return function(node) {
		var tooltip = [];
		var string = '';

		if(node.info.node !== '' && typeof node.info.node !== 'undefined') {
			var eth_version = node.info.node.split('/');

			if(eth_version[1][0] !== 'v' && eth_version[1][2] !== '.')
			{
				eth_version.splice(1,1);
			}

			string = "<b>" + node.info.node + "</b>";
			tooltip.push(string);

			string = "Version: <b>" + (eth_version[1]) + "</b>";
			tooltip.push(string);
		}

		if(node.info.net !== '') {
			string = "Network: <b>" + (typeof node.info.net !== 'undefined' ? node.info.net : '-') + "</b>";

			tooltip.push(string);
		}

		if(node.info.protocol !== '') {
			string = "Protocol: <b>" + (typeof node.info.protocol !== 'undefined' ? node.info.protocol : '-') + "</b>";

			tooltip.push(string);
		}

		if(node.info.port !== '') {
			string = "Port: <b>" + (typeof node.info.port !== 'undefined' ? node.info.port : '30303') + "</b>";

			tooltip.push(string);
		}

		if(node.info.api !== '') {
			string = "Web3: <b>" + node.info.api + "</b>";

			tooltip.push(string);
		}

		if(node.info.client !== '') {
			string = "API: <b>" + (typeof node.info.client !== 'undefined' ? node.info.client : '<= 0.0.3') + "</b>";

			tooltip.push(string);
		}

		if(node.info.os !== '') {
			string = "OS: <b>" + (typeof node.info.os !== 'undefined' ? node.info.os + ' ' + node.info.os_v : '?') + "</b>";

			tooltip.push(string);
		}

		if(node.geo !== null)
		{
			string = "Location: <b>";

			if(node.geo.city !== '')
				string += node.geo.city + ", ";
			string += node.geo.country + "</b>";

			tooltip.push(string);
		}

		if(node.info.contact !== '') {
			string = "Contact: <b>" + (typeof node.info.contact !== 'undefined' ? node.info.contact : '-') + "</b>";

			tooltip.push(string);
		}

		return tooltip.join("<br>");
	};
})
.filter('bubbleClass', function() {
	return function(node, bestBlock) {
		return mainClass(node, bestBlock).replace('text-', '');
	};
})
.filter('minerNameFilter', function() {
	return function(address, name) {
		if(typeof name !== 'undefined' && name !== false && name.length > 0)
			return name;

		return address.replace('0x', '');
	};
})
.filter('minerBlocksClass', function() {
	return function(blocks, prefix) {
		if(typeof prefix === 'undefined')
			prefix = 'bg-';
		if(blocks <= 6)
			return prefix + 'success';

		if(blocks <= 12)
			return prefix + 'info';

		if(blocks <= 18)
			return prefix + 'warning';

		return prefix + 'danger';
	};
})
.filter('nodeClientClass', function() {
	return function(info, current) {
		if(typeof info === 'undefined' || typeof info.client === 'undefined' || typeof info.client === '')
			return 'text-danger';

		if(compareVersions(info.client, '<', current))
			return 'text-danger';

		return 'hidden';
	};
})
.filter('consensusClass', function() {
	return function(nodes, bestBlock) {
		var status = 'success';
		var now = _.now();

		for(var x = 0; x < nodes.length; x++)
		{
			if(nodes[x].stats.block.number === bestBlock.number && nodes[x].stats.block.hash !== bestBlock.hash)
				return 'danger';

			if((bestBlock.number - nodes[x].stats.block.number) > 1 && (now - bestBlock.received) >= 20*1000)
				status = 'orange';

			if((bestBlock.number - nodes[x].stats.block.number) === 1 && (now - bestBlock.received) >= 10*1000 && status !== 'orange')
				status = 'warning';
		}

		return status;
	};
})
.filter('consensusFilter', function() {
	return function(nodes, bestBlock) {
		var cnt = 0;

		for(var x = 0; x < nodes.length; x++)
		{
			if(nodes[x].stats.block.number === bestBlock.number && nodes[x].stats.block.hash === bestBlock.hash)
				cnt++;
		}

		return cnt + '/' + nodes.length;
	};
});

function compareVersions(v1, comparator, v2)
{
	comparator = comparator == '=' ? '==' : comparator;

	var v1parts = v1.split('.'), v2parts = v2.split('.');
	var maxLen = Math.max(v1parts.length, v2parts.length);
	var part1, part2;
	var cmp = 0;

	for(var i = 0; i < maxLen && !cmp; i++)
	{
		part1 = parseInt(v1parts[i], 10) || 0;
		part2 = parseInt(v2parts[i], 10) || 0;
		if(part1 < part2)
			cmp = 1;
		if(part1 > part2)
			cmp = -1;
	}

	return eval('0' + comparator + cmp);
}

function mainClass(node, bestBlock)
{
	if( ! node.active)
		return 'text-gray';

	if(node.peers === 0)
		return 'text-danger';

	return peerClass(node.peers, node.active);
}

function peerClass(peers, active)
{
	if( ! active)
		return 'text-gray';

	return (peers <= 1 ? 'text-danger' : (peers > 1 && peers < 4 ? 'text-warning' : 'text-success'));
}

function timeClass(timestamp)
{
	var diff = ((new Date()).getTime() - timestamp)/1000;

	return blockTimeClass(diff);
}

function blockTimeClass(diff)
{
	if(diff <= 35)
		return 'text-success';

	if(diff <= 50)
		return 'text-warning';

	if(diff <= 70)
		return 'text-orange';

	return 'text-danger'
};

/* Directives */

angular.module('netStatsApp.directives', [])
	.directive('appVersion', ['version', function (version) {
		return function(scope, elm, attrs) {
			elm.text(version);
		};
}])
// 	.directive('timeAgo', ['$interval', function($interval) {
// 		function link (scope, element, attrs)
// 		{
// 			var timestamp,
// 				timeoutId;

// 			function updateTime() {
// 				element.text(timeAgo())
// 			}

// 			function timeAgo()
// 			{
// 				if(timestamp === 0)
// 					return '∞';

// 				var time = (new Date()).getTime();
// 				var diff = Math.floor((time - timestamp)/1000);

// 				if(diff < 60)
// 					return Math.round(diff) + ' s ago';

// 				return moment.duration(Math.round(diff), 's').humanize() + ' ago';
// 			};

// 			scope.$watch(attrs.timeAgo, function(value) {
// 				timestamp = value;
// 				updateTime();
// 			});

// 			element.on('$destroy', function() {
// 				$interval.cancel(timeoutId);
// 			});

// 			timeoutId = $interval(function () {
// 				updateTime();
// 			}, 200);
// 		};

// 		return {
// 			link: link
// 		};
// }])

	.directive('minerblock', function ($compile) {
		return {
			restrict: 'E',
			template: '<div></div>',
			replace: true,
			link: function (scope, element, attrs)
			{
				var makeClass = function (value)
				{
					if(value <= 6)
						return 'success';

					if(value <= 12)
						return 'info';

					if(value <= 18)
						return 'warning';

					if(value <= 24)
						return 'orange';

					return 'danger';
				}

				attrs.$observe("blocks", function (newValue)
				{
					var content = '';
					var blockClass = 'bg-' + makeClass(newValue);

					for(var i = 0; i < newValue; i++)
					{
						content += '<div class="block ' + blockClass + '"></div>';
					}

					element.empty();
					element.html(content);
				});
			}
		};
})
	.directive('sparkchart', function () {
		return {
			restrict: 'E',
			scope: {
				data: '@'
			},
			compile: function (tElement, tAttrs, transclude)
			{
				tElement.replaceWith('<span>' + tAttrs.data + "</span>");

				return function(scope, element, attrs)
				{
					attrs.$observe("data", function (newValue)
					{
						element.html(newValue);
						element.addClass("big-details");
						element.sparkline('html', {
							type: 'bar',
							tooltipSuffix: (attrs.tooltipsuffix || '')
						});
					});
				};
			}
		};
})
	.directive('nodepropagchart', function() {
		return {
			restrict: 'E',
			scope: {
				data: '@'
			},
			compile: function (tElement, tAttrs, transclude)
			{
				tElement.replaceWith('<span>' + tAttrs.data + "</span>");

				function formatTime (ms) {
					var result = 0;

					if(ms < 1000) {
						return ms + " ms";
					}

					if(ms < 1000*60) {
						result = ms/1000;
						return result.toFixed(1) + " s";
					}

					if(ms < 1000*60*60) {
						result = ms/1000/60;
						return Math.round(result) + " min";
					}

					if(ms < 1000*60*60*24) {
						result = ms/1000/60/60;
						return Math.round(result) + " h";
					}

					result = ms/1000/60/60/24;
					return Math.round(result) + " days";
				};

				return function(scope, element, attrs)
				{
					attrs.$observe("data", function (newValue)
					{
						element.html(newValue);
						element.sparkline('html', {
							type: 'bar',
							negBarColor: '#7f7f7f',
							zeroAxis: false,
							height: 20,
							barWidth : 2,
							barSpacing : 1,
							tooltipSuffix: '',
							chartRangeMax: 8000,
							colorMap: jQuery.range_map({
								'0:1': '#10a0de',
								'1:1000': '#7bcc3a',
								'1001:3000': '#FFD162',
								'3001:7000': '#ff8a00',
								'7001:': '#F74B4B'
							}),
							tooltipFormatter: function (spark, opt, ms) {
								var tooltip = '<div class="tooltip-arrow"></div><div class="tooltip-inner">';
								tooltip += formatTime(ms[0].value);
								tooltip += '</div>';

								return tooltip;
							}
						});
					});
				};
			}
		};
})
	.directive('nodemap', ['$compile', function($compile) {
		return {
			restrict: 'EA',
			scope: {
				data: '='
			},
			link: function(scope, element, attrs) {
				var bubbleConfig = {
					borderWidth: 0,
					highlightOnHover: false,
					popupOnHover: true,
					popupTemplate: function(geo, data) {
						return ['<div class="tooltip-arrow"></div>',
								'<div class="hoverinfo ' + data.fillClass + '">',
									'<div class="propagationBox"></div>',
									'<strong>',
									data.nodeName,
									'</strong>',
								'</div>'].join('');
					}
				};

				scope.init = function() {
					element.empty();

					var width = 628,
						height = 202;

					scope.map = new Datamap({
						element: element[0],
						scope: 'world',
						width: width,
						height: 242,
						fills: {
							success: '#7BCC3A',
							info: '#10A0DE',
							warning: '#FFD162',
							orange: '#FF8A00',
							danger: '#F74B4B',
							defaultFill: '#282828'
						},
						geographyConfig: {
							borderWidth: 0,
							borderColor: '#000',
							highlightOnHover: false,
							popupOnHover: false
						},
						bubblesConfig: {
							borderWidth: 0,
							highlightOnHover: false,
							popupOnHover: true
						},
						done: function(datamap) {
							var ev;

							var zoomListener = d3.behavior.zoom()
								.size([width, height])
								.scaleExtent([1, 3])
								.on("zoom", redraw)
								.on("zoomend", animadraw);

							function redraw() {
								datamap.svg.select(".datamaps-subunits").attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")");
								datamap.svg.select(".bubbles").selectAll("circle")
									.attr("transform", "translate(" + d3.event.translate + ")scale(" + d3.event.scale + ")")
									.attr("r", 3/d3.event.scale);

								ev = d3.event;
							}

							zoomListener(datamap.svg);

							function animadraw() {
								var x = Math.min(0, Math.max(ev.translate[0], (-1) * width * (ev.scale-1)));
								var y = Math.min(0, Math.max(ev.translate[1], (-1) * height * (ev.scale-1)));

								datamap.svg.select(".datamaps-subunits")
									.transition()
									.delay(150)
									.duration(750)
									.attr("transform", "translate(" + x  + "," + y + ")scale(" + ev.scale + ")");

								datamap.svg.select(".bubbles").selectAll("circle")
									.transition()
									.delay(150)
									.duration(750)
									.attr("transform", "translate(" + x  + "," + y + ")scale(" + ev.scale + ")")
									.attr("r", 3/ev.scale);

								zoomListener.translate([x,y]);
							}
						}
					});

					scope.map.bubbles(scope.data, bubbleConfig);
				}

				scope.init();

				scope.$watch('data', function() {
					scope.map.bubbles(scope.data, bubbleConfig);
				}, true);
			}
		};
}])
	.directive('histogram', ['$compile', function($compile) {
		return {
			restrict: 'EA',
			scope: {
				data: '='
			},
			link: function(scope, element, attrs)
			{
				var margin = {top: 0, right: 0, bottom: 0, left: 0};
				var width = 280 - margin.left - margin.right,
					height = 63 - margin.top - margin.bottom;

				var TICKS = 40;

				var x = d3.scale.linear()
					.domain([0, 10000])
					.rangeRound([0, width])
					.interpolate(d3.interpolateRound);

				var y = d3.scale.linear()
					.range([height, 0])
					.interpolate(d3.interpolateRound);

				var color = d3.scale.linear()
					.domain([1000, 3000, 7000, 10000])
					.range(["#7bcc3a", "#FFD162", "#ff8a00", "#F74B4B"]);

				var xAxis = d3.svg.axis()
					.scale(x)
					.orient("bottom")
					.ticks(4, ",.1s")
					.tickFormat(function(t){ return t/1000 + "s"});

				var yAxis = d3.svg.axis()
					.scale(y)
					.orient("left")
					.ticks(3)
					.tickFormat(d3.format("%"));

				var line = d3.svg.line()
					.x(function(d) { return x(d.x + d.dx/2) - 1; })
					.y(function(d) { return y(d.y) - 2; })
					.interpolate('basis');

				var tip = d3.tip()
					.attr('class', 'd3-tip')
					.offset([10, 0])
					.direction('s')
					.html(function(d) {
						return '<div class="tooltip-arrow"></div><div class="tooltip-inner"><b>' + (d.x/1000) + 's - ' + ((d.x + d.dx)/1000) + 's</b><div class="small">Percent: <b>' + Math.round(d.y * 100) + '%</b>' + '<br>Frequency: <b>' + d.frequency + '</b><br>Cumulative: <b>' + Math.round(d.cumpercent*100) + '%</b></div></div>';
					})

				scope.init = function()
				{
					var data = scope.data;

					// Adjust y axis
					y.domain([0, d3.max(data, function(d) { return d.y; })]);

					// Delete previous histogram
					element.empty();

					/* Start drawing */
					var svg = d3.select(".d3-blockpropagation").append("svg")
						.attr("width", width + margin.left + margin.right)
						.attr("height", height + margin.top + margin.bottom)
					  .append("g")
						.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

					svg.call(tip);

					svg.append("g")
						.attr("class", "x axis")
						.attr("transform", "translate(0," + height + ")")
						.call(xAxis)
						.selectAll("text")
						  .attr("y", 6);

					svg.append("g")
						.attr("class", "y axis")
						.attr("transform", "translate(" + width + ", 0)")
						.call(yAxis);


					var bar = svg.append("g")
						.attr("class", "bars")
					  .selectAll("g")
					  .data(data)
					  .enter().append("g")
						.attr("transform", function(d) { return "translate(" + x(d.x) + ",0)"; })
						.on('mouseover', function(d) { tip.show(d, d3.select(this).select('.bar').node()); })
						.on('mouseout', tip.hide);

					bar.insert("rect")
						.attr("class", "handle")
						.attr("y", 0)
						.attr("width", x(data[0].dx + data[0].x) - x(data[0].x))
						.attr("height", function(d) { return height; });

					bar.insert("rect")
						.attr("class", "bar")
						.attr("y", function(d) { return y(d.y); })
						.attr("rx", 1)
						.attr("ry", 1)
						.attr("fill", function(d) { return color(d.x); })
						.attr("width", x(data[0].dx + data[0].x) - x(data[0].x) - 1)
						.attr("height", function(d) { return height - y(d.y) + 1; });

					bar.insert("rect")
						.attr("class", "highlight")
						.attr("y", function(d) { return y(d.y); })
						.attr("fill", function(d) { return d3.rgb(color(d.x)).brighter(.7).toString(); })
						.attr("rx", 1)
						.attr("ry", 1)
						.attr("width", x(data[0].dx + data[0].x) - x(data[0].x) - 1)
						.attr("height", function(d) { return height - y(d.y) + 1; });

					svg.append("path")
						.attr("class", "line")
						.attr("d", line(data));
				}

				scope.$watch('data', function() {
					if(scope.data.length > 0) {
						scope.init();
					}
				}, true);
			}
		};
	}]);;
(function() {
	$('body').on('mouseenter', '[data-toggle="tooltip"]', function( event ) {
		$(this).tooltip('show');
	}).on('mouseleave', '[data-toggle="tooltip"]', function( event ) {
		$(this).tooltip('hide');
	});

	$.fn.sparkline.defaults.bar.height = 63;
	$.fn.sparkline.defaults.bar.barWidth = 6;
	$.fn.sparkline.defaults.bar.barSpacing = 1;
	$.fn.sparkline.defaults.bar.tooltipClassname = 'jqstooltip';
	$.fn.sparkline.defaults.bar.tooltipOffsetX = 0;
	$.fn.sparkline.defaults.bar.tooltipFormat = $.spformat('<div class="tooltip-arrow"></div><div class="tooltip-inner">{{prefix}}{{value}} {{suffix}}</div>');
	$.fn.sparkline.defaults.bar.colorMap = $.range_map({
		'0:6': '#10a0de',
		'6:35': '#7bcc3a',
		'35:50': '#FFD162',
		'50:70': '#ff8a00',
		'70:': '#F74B4B'
	});

	moment.relativeTimeThreshold('s', 60);
	moment.relativeTimeThreshold('m', 60);
	moment.relativeTimeThreshold('h', 24);
	moment.relativeTimeThreshold('d', 28);
	moment.relativeTimeThreshold('M', 12);

})();

(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

ga('create', 'UA-85399009-1', 'auto');
ga('send', 'pageview');